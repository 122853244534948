<template>
  <base-card>
    <v-card-text>
      <v-btn
        v-if="isAdminUser"
        color="primary"
        class="ml-3"
        @click.native="onCreateCustomersWithOrder"
      >
        {{ $t('user.createNewAccountWithOrder') }}
      </v-btn>
      <v-data-table
        :headers="headers"
        :items="userList"
        :items-per-page="paginationLimit"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2"
      >
        <template v-slot:item.name="{item}">
          <span class="font-weight-bold">{{ item.firstName || item.lastName ? `${item.firstName} ${item.lastName}` : $t('common.unknown') }}</span>
        </template>

        <template v-slot:item.partnerName="{item}">
          {{ item.partner && item.partner.companyName || '' }}
        </template>

        <template v-slot:item.isActive="{item}">
          <v-chip
            v-if="item.isActive"
            class="px-4"
            color="primary white--text"
          >
            <v-icon left>
              mdi-account-check
            </v-icon>
            {{ $t('common.active') }}
          </v-chip>
          <v-chip
            v-else
            class="px-4"
            color="danger white--text"
          >
            <v-icon left>
              mdi-close-circle
            </v-icon>
            {{ $t('common.inActive') }}
          </v-chip>
        </template>

        <template v-slot:item.updatedAt="{item}">
          {{ util.formatDateTime(item.updatedAt) }}
        </template>

        <template v-slot:item.createdAt="{item}">
          {{ util.formatDateTime(item.createdAt) }}
        </template>

        <template v-slot:item.action="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="ma-1"
                fab
                x-small
                color="success"
                @click.native="activateUser(item)"
                v-if="item.isClosed || !item.isActive"
              >
                <v-icon>mdi-account-convert-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common.activate") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
    showQuestionDialog,
  } from '@/utils/swalDialog'

  export default {
    metaInfo: {
      title: 'Users',
    },

    data () {
      return {
        userList: [],
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [],
        util: util,
      }
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
      }),
    },

    watch: {
      currentPage(val) {
        this.currentPage = val
        this.loadUsers()
      }
    },

    mounted () {
      if (this.isAdminUser) {
        this.headers = [
          { value: 'index', text: this.$t('common.number'), width: 80 },
          { value: 'name', text: this.$t('common.name'), width: 250 },
          { value: 'email', text: this.$t('common.email'), width: 280 },
          {
            value: 'partnerName',
            text: this.$t('partner.companyName'),
            width: 180,
          },
          { value: 'isActive', text: this.$t('user.active'), width: 180 },
          { value: 'updatedAt', text: this.$t('common.updatedAt'), width: 120 },
          { value: 'createdAt', text: this.$t('common.createdAt'), width: 120 },
          { value: 'action', text: this.$t('common.action'), width: 100 },
        ]
      } else {
        this.headers = [
          { value: 'index', text: this.$t('common.number'), width: 80 },
          { value: 'name', text: this.$t('common.name'), width: 250 },
          { value: 'email', text: this.$t('common.email'), width: 280 },
          { value: 'isActive', text: this.$t('user.active'), width: 180 },
          { value: 'updatedAt', text: this.$t('common.updatedAt'), width: 120 },
          { value: 'createdAt', text: this.$t('common.createdAt'), width: 120 },
          { value: 'action', text: this.$t('common.action'), width: 100 },
        ]
      }

      this.loadUsers()
    },

    methods: {
      ...mapActions({
        fetchUsers: 'fetchUsers',
        updateUserProfile: 'updateUserProfile',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        createCustomersWithOrder: 'createCustomersWithOrder',
      }),

      async loadUsers () {
        const pagination = {
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const response = await this.fetchUsers(pagination)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.userList = response.rows.map((user, index) => {
            return {
              ...user,
              index: (this.currentPage - 1) * this.paginationLimit + index + 1,
            }
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      async activateUser (row) {
        const updateData = {
          ...row,
          isActive: true,
          isClosed: false,
        }

        const btnResponse = await showQuestionDialog(
          this.$t('user.reactiveAccount'),
          this.$t('user.areYouSureReactivateAccount'),
          this.$t('user.reactivate')
        )

        if (!btnResponse.value) {
          return
        }

        this.setLoadingText(this.$t('user.reactivatingUser'))
        this.setLoading(true)
        try {
          const user = await this.updateUserProfile(updateData)
          const updatedUser = {
            ...user,
            index: row.index,
          }

          this.userList.splice((row.index - 1) % this.paginationLimit, 1, updatedUser)
          showSuccessDialog(this.$t('user.reactivateAccountSuccessfully'))
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorMessage)
        }
        this.setLoading(false)
      },

      async onCreateCustomersWithOrder() {
        this.setLoading(true)
        this.setLoadingText(`${this.$t('user.creatingCustomers')}...`)
        try {
          const response = await this.createCustomersWithOrder();

          if (response.successCount > 0) {
            showSuccessDialog(this.$t('user.createNewAccountsSuccessfully', {count: response.successCount}))
          }

          if (this.currentPage === 1) {
            this.loadUsers()
          } else {
            this.currentPage = 1
          }
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },
    }
  }
</script>
